export const pricingMenu = [
  {
    title: 'Free Trial',
    price: 0,
    period: 'first week',
    description: 'Exploration limited first week',
    options: ['Try all features free for 7 days', 'Start creating risk-free'],
    shortOptions: [],
    bestSeller: false,
  },
  {
    title: 'Full Access Plan',
    price: 97,
    period: 'mo',
    description: 'All-in-one Ecom Business Builder',
    shortOptions: ['POD', 'Dropshipping', 'Brand Builder '],
    options: [
      'Rapid 10 minutes set up',
      'AI product content generation',
      'Full access to over 1 million products',
      'Full website builder',
      'Multi platform publishing',
    ],
    bestSeller: true,
  },
];
