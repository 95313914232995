// BusinessTypeMenuItem.tsx
import React from 'react';

interface BusinessTypeMenuItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  shortLabel?: string;
  isActive?: boolean;
}

const BusinessTypeMenuItem: React.FC<BusinessTypeMenuItemProps> = ({
  label,
  isSelected,
  onClick,
  shortLabel,
  isActive = true,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isActive}
      className={`flex justify-center items-center flex-grow-0 flex-shrink-0
        xl:w-[200px] lg:w-[184px] md:w-[153px] xs:w-auto 
        relative gap-2.5
        ${
          isSelected
            ? 'bg-[#6839ee] text-white'
            : `bg-[#eff1f4] text-[#0c062c]  ${isActive ? 'opacity-40' : ''} `
        }
        xl:h-14 lg:h-[48px] md:h-[40px] xs:h-[32px]
        md:px-8 xs:px-4
        md:rounded-xl xs:rounded-[4px]
        transition-colors duration-300
      `}
    >
      <p className="flex-grow-0 flex-shrink-0 font-medium text-center">
        <span className="md:hidden">{shortLabel ? shortLabel : label}</span>
        {label && <span className="hidden md:block">{label}</span>}
      </p>
    </button>
  );
};

export default BusinessTypeMenuItem;
