import aiBusinessImage from '../../assets/pages/businessModels/ai-customize-images-generation.gif';
import generateTextsHeadlines from '../../assets/pages/businessModels/generate-texts-headlines.png';
import onlineBusinessBuilder from '../../assets/pages/businessModels/online-business-builder.gif';
import quickMultiPlatformPublishing from '../../assets/pages/businessModels/quick-multi-platform-publishing.png';
import quickMultiProductLaunch from '../../assets/pages/businessModels/quick-multi-product-launch.gif';
import productPageAIcopywriting from '../../assets/pages/businessModels/product-page-ai-copywriting.png';
import productDesignEditor from '../../assets/pages/businessModels/product-design-editor.gif';

import dropshippingAliexpressGif from '../../assets/pages/businessModels/dropshipping/aliexpress.gif';
import dropshippingProductPreviewGif from '../../assets/pages/businessModels/dropshipping/product-preview.png';
import dropshippingRevenueGif from '../../assets/pages/businessModels/dropshipping/revenue.gif';
import dropshippingTableStatusGif from '../../assets/pages/businessModels/dropshipping/table-statuses.png';

export const pod = [
  {
    image: productDesignEditor,
    text: 'Product Design Editor',
    description:
      'Use our built in design editor to enhance your AI generated images. Add text, transparency and much more!',
  },

  {
    image: generateTextsHeadlines,
    text: 'Generate Texts & Headlines',
    description:
      'Generate sales focused texts and headlines tailored to the platform you advertise on. Let our Artificial Intelligence be your copy-writer so you can focus on what really matters; your business',
  },

  {
    image: onlineBusinessBuilder,
    text: 'Online business builder',
    description:
      'Efficient launch in 60 seconds without hiring designers, developers or spending days on setup',
  },
  {
    image: quickMultiPlatformPublishing,
    text: 'Quick multi-platform publishing',
    description:
      'Expands market presence on multiple platforms Amazon, Etsy, Shopify, Facebook Ads, Instagram, and TikTok ',
  },
  {
    image: quickMultiProductLaunch,
    text: 'Quick multi-product launch in 60 seconds',
    description:
      '900+ products to choose from. Launch 5 product types at the same time',
  },
  {
    image: productPageAIcopywriting,
    text: 'Product page AI copywriting',
    description:
      'AI automatically writes your product title, description and all other text',
  },
  {
    image: aiBusinessImage,
    text: 'AI customizable image generation',
    description:
      'Generate conversion-focused ad creatives in a matter of minutes, while staying on brand. Test more creatives while decreasing time-spent on design process and get up to 14x better conversion and click-through-rates with our unique AI',
  },
];

export const dropshipping = [
  {
    image: dropshippingAliexpressGif,
    text: 'Generate high-converting product descriptions in seconds',
    description:
      'Generate sales focused texts and headlines tailored to the platform you advertise on. Let our Artificial Intelligence be your copy-writer so you can focus on what really matters; your business!',
  },

  {
    image: dropshippingTableStatusGif,
    text: 'Order Management Hub',
    description:
      'Efficiently manage all aspects of order processing with the Order Managment Hub. Seamlessly track, update, and fulfill orders to ensure accuracy and customer satisfaction.',
  },

  {
    image: dropshippingRevenueGif,
    text: 'Sales Analytics Dashboard',
    description:
      'This is a comprehensive tool for real-time sales analytics. Monitor key metrics, track trends, and analyze performance in a single intuitive interface.',
  },
  {
    image: dropshippingProductPreviewGif,
    text: 'Multi-Product Publishing',
    description: `Streamline your store with Multi-Product Publishing. Add several dropshipping products at once to save time and keep your store updated. It's fast, easy, and effective.`,
  },
];

// menu.ts
export type BusinessType = 'POD' | 'Dropshipping' | 'Branded';

export interface BusinessSelectItem {
  text: string;
  description: string;
  image: string;
}

export const businessTypes: BusinessType[] = ['POD', 'Dropshipping', 'Branded'];

export const businessSelectData: Record<BusinessType, BusinessSelectItem[]> = {
  POD: pod,
  Dropshipping: dropshipping,
  Branded: [
    // Add more Branded items
  ],
};
