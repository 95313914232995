export const colors = {
  main: {
    primary: {
      300: '#A793F6',
      400: '#8C72F3',
      500: '#7354F0',
      600: '#6839EE',
      700: '#601EEC',
      800: '#5813DD',
      alpha: {
        8: 'rgb(104, 57, 238, 0.08)',
        16: 'rgb(104, 57, 238, 0.16)',
        24: 'rgb(104, 57, 238, 0.24)',
        32: 'rgb(104, 57, 238, 0.32)',
        40: 'rgb(104, 57, 238, 0.40)',
      },
    },
    secondary: {
      300: '#E2A4F8',
      400: '#CF84F3',
      500: '#BF65F0',
      600: '#B349ED',
      700: '#A72DEA',
      800: '#9B17E4',
      alpha: {
        8: 'rgb(179, 73, 237, 0.08)',
        16: 'rgb(179, 73, 237, 0.16)',
        24: 'rgb(179, 73, 237, 0.24)',
        32: 'rgb(179, 73, 237, 0.32)',
        40: 'rgb(179, 73, 237, 0.40)',
      },
    },
    tertiary: {
      400: '#EEFDC9',
      500: '#D9FA8A',
      600: '#C8F754',
      700: '#B7F41E',
      800: '#9ED90B',
    },
    background: {
      light: {
        100: '#FFFFFF',
        200: '#FAFAFB',
        300: '#F5F6F7',
        400: '#EFF1F4',
      },
      dark: {
        100: '#1A0D60',
        200: '#0C062C',
        300: '#020315',
      },
    },
    textIcon: {
      dark: '#0C062C',
      darkGrey: 'rgb(12, 6, 44, 0.8)',
      grey: 'rgb(12, 6, 44, 0.6)',
      lightGrey: 'rgb(12, 6, 44, 0.4)',
      'light-60': 'rgb(217, 217, 217, 0.6)',
      'light-80': 'rgb(217, 217, 217, 0.8)',
      light: '#FFFFFF',
    },
  },
};
