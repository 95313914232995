import React from 'react';
import { HashtagCardInterface } from './index.d';
const HashtagCard = ({ Icon, text, color }: HashtagCardInterface) => {
  return (
    <div
      className={`flex flex-col w-fit justify-center items-start
       flex-grow-0 flex-shrink-0 md:pl-2 md:pr-3 md:pt-1.5 md:pb-2.5
       xs:pl-2 xs:pr-3 xs:pt-1 xs:pb-1
       xl:rounded-[40px] lg:rounded-[30px] md:rounded-[24px] xs:rounded-[40px] 
       `}
      data-aos="fade-up"
      style={{ backgroundColor: color }}
    >
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
        <Icon
          className="xl:h-[32px] xl:w-max
         md:h-[24px] md:w-[24px] 
         xs:w-[20px] xs:h-[20px] "
        />
        <p
          className="flex-grow-0 flex-shrink-0
          text-left text-[#0c062c] font-outfit 
          xl:text-32 lg:text-24 md:text-24 sm:text-18 xs:text-16
           font-[400]"
          style={{
            fontFamily: 'Outfit',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '100%',
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default HashtagCard;
