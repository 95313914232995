import Average95 from '../../assets/pages/whoIsFor/averate_9_5.gif';
import { ReactComponent as TeachersEducators } from '../../assets/pages/whoIsFor/teachers_educators.svg';

import Housewives from '../../assets/pages/whoIsFor/houswives.gif';

import { ReactComponent as Employees95 } from '../../assets/pages/whoIsFor/9_5_employees.svg';

import RetailEmployees from '../../assets/pages/whoIsFor/retail_employees.gif';

import Admins from '../../assets/pages/whoIsFor/admins.gif';
import Designers from '../../assets/pages/whoIsFor/designers.gif';
import { ReactComponent as FreelancersAsset } from '../../assets/pages/whoIsFor/freelancers.svg';
import Students from '../../assets/pages/whoIsFor/students.gif';
import { ReactComponent as StayAtHome } from '../../assets/pages/whoIsFor/stay-at-home-parents.svg';
import DigitalNomads from '../../assets/pages/whoIsFor/digital-nomads.gif';
import { ReactComponent as IndividualSeekingSmooth } from '../../assets/pages/whoIsFor/individuals-seeking-a-smooth-process.svg';

export const images = [
  {
    Image: Average95,
    title: 'You average 9-5 individual',
    format: 'gif',
  },
  {
    Image: TeachersEducators,
    title: 'Teachers /Educators',
    format: 'svg',
  },
  {
    Image: Housewives,
    title: 'Housewives',
    format: 'gif',
  },
  {
    Image: Employees95,
    title: '9-5 employees',
    format: 'svg',
  },
  {
    Image: RetailEmployees,
    title: 'Retail employees',
    format: 'gif',
  },
  {
    Image: Admins,
    title: 'Admins',
    format: 'gif',
  },
  {
    Image: FreelancersAsset,
    title: 'Freelancers',
    format: 'svg',
  },
  {
    Image: Designers,
    title: 'Designers',
    format: 'gif',
  },
  {
    Image: Students,
    title: 'Students',
    format: 'gif',
  },
  {
    Image: StayAtHome,
    title: 'Stay-at-home parents',
    format: 'svg',
  },
  {
    Image: DigitalNomads,
    title: 'Digital nomads',
    format: 'gif',
  },
  {
    Image: IndividualSeekingSmooth,
    title: 'Individuals seeking a smooth process',
    format: 'svg',
  },
];
