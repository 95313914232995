export const defaultQuestions = [
  {
    content: 'What is a “Lanchify”?',
    answer: `Answer … Launchify is your gateway to e-commerce success.
  Perfect for beginners and experts alike.&nbsp; Launchify uses
  AI to create and launch your business in minutes. From website
  creation, choosings a product, designing your product,
  publishing it on your website, Amazon and ETSY. Without
  needing a web developer, web designer, copywriter, inventory,
  fulfilment or even shipping. Everything is done for you.`,
  },
  {
    content: 'What type of products can I sell using Launchify?',
    answer:
      'Launchify empowers you to sell over 900 products that you can put your own AI generated designs on. From mugs, tshirts, hoodies, wall art, key rigs, sneakers and much more. ',
  },
  {
    content: 'Do I need technical skills to use Launchify?',
    answer:
      'No technical skills are required. This software was used by a 13 year old to build a full ecommerce business. So anyone can do it.',
  },
  {
    content: 'Can I integrate with my existing e-commerce platforms?',
    answer:
      '100% you can. Just follow our onboarding process and you’re done. Takes 60 seconds.',
  },
  {
    content: 'How does Launchify handle order fulfilment and shipping? ',
    answer:
      'Launchify automates the entire process. Once the customer makes a purchase, the order is automatically sent to the suppliers, and when ready, it’s shipped directly to the customer. You can sit back and collect your profits without manual intervention. ',
  },
];
