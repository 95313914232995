import './App.css';

import BusinessModelsPage from './pages/BusinessModels';
import LandingPage from './pages/Landing';
import PriceTablePage from './pages/PriceTable';
import StocksPage from './pages/Stocks';

import AOS from 'aos'; // Ensure AOS is imported
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ScreenDivider } from './assets/main/screen-divider.svg';
import LogoCard from './components/cards/LogoCard';
import FAQ from './pages/FAQ';
import Footer from './pages/Footer';
import Journey from './pages/Journey';
import OnePlace from './pages/OnePlace';
import Pricing from './pages/Pricing';
import WhoIsFor from './pages/WhoIsFor';

function App() {
  // State to manage the visibility of the menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const touchStartRef = useRef(0);
  const touchEndRef = useRef(0);
  // Function to handle link clicks
  const handleLinkClick = (event: any) => {
    event.preventDefault(); // Prevent the default anchor behavior
    const target = event.target.getAttribute('href'); // Get the href attribute of the clicked link

    // Smoothly scroll to the target element
    const scrollToElement = document.querySelector(target);
    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth' });
    }

    setIsMenuOpen(false); // Close the menu
  };
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    AOS.refresh();
  }, []);

  const handleTouchStart = (e: any) => {
    touchStartRef.current = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e: any) => {
    touchEndRef.current = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartRef.current - touchEndRef.current > 150) {
      // If the swipe is right to left and exceeds 75px
      setIsMenuOpen(true);
    }
  };
  return (
    <div className="App font-outfit  flex flex-col overflow-y-auto overflow-x-hidden bg-white">
      {isMenuOpen && (
        <div className="flex flex-col fixed md:hidden w-full inset-0 bg-[#D7CBF9]  z-[9999] ">
          <div className="flex flex-row justify-between pt-2 pr-3">
            <LogoCard />
            <button className="" onClick={() => setIsMenuOpen(false)}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.75 11L11 20.75M11 11L20.75 20.75"
                  stroke="#4E4E53"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            className="  mt-[60px]
            flex flex-col justify-center items-center "
          >
            <div className="text-[#0C062C] text-center text-20 ">
              <ul className="flex flex-col gap-[40px]">
                <a href="#benefits" onClick={handleLinkClick}>
                  Benefits
                </a>
                <a href="#process" onClick={handleLinkClick}>
                  Process
                </a>
                <a href="#products" onClick={handleLinkClick}>
                  Products
                </a>
                <a href="#pricing" onClick={handleLinkClick}>
                  Pricing
                </a>
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* Apply data-aos to each page component */}
      <LandingPage
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        data-aos="fade-up"
      />

      <ScreenDivider data-aos="fade-up" className=" z-10 w-full h-max" />

      <BusinessModelsPage data-aos="fade-up" />
      <PriceTablePage data-aos="fade-up" />
      <Journey data-aos="fade-up" />
      <StocksPage data-aos="fade-up" />

      <ScreenDivider
        className="z-10 w-full h-max"
        style={{ transform: 'rotate(180deg)' }}
        data-aos="fade-up"
      />

      <OnePlace data-aos="fade-up" />
      <ScreenDivider data-aos="fade-up" className=" z-10 w-full h-max" />
      <WhoIsFor data-aos="fade-up" />
      <Pricing data-aos="fade-up" />
      <FAQ data-aos="fade-up" />
      <Footer data-aos="fade-up" />
    </div>
  );
}

export default App;
