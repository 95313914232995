import { useEffect, useState } from 'react';
import { images } from './menu';

const WhoIsFor = () => {
  const [isNarrowScreen, setIsNarrowScreen] = useState(
    window.innerWidth < 1440
  );

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1440);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div
      className="flex bg-white xl:pb-[128px] lg:pb-[96px] md:pb-[80px] md:py-[0px] md:pt-0 xs:py-[16px] flex-col justify-start items-center
     w-full h-full relative overflow-hidden"
    >
      <p
        data-aos="fade-up" className="xl:text-[48px] lg:text-[40px] md:text-[32px] xs:text-[24px] font-medium text-left text-[#0c062c]">
        Who is this for? Everybody
      </p>
      <div
        data-aos="fade-up"
        className="md:px-[40px] xs:px-[19px] w-full justify-start
         items-start md:gap-4 xs:gap-[12px] md:gap-y-[60px] xs:gap-y-[24px]
         xl:mt-[80px] lg:mt-[80px] md:mt-[80px] sm:mt-[64px] grid xl:grid-cols-6
       lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-2 max-w-[1800px]"
      >
        {(isNarrowScreen ? images.slice(0, 8) : images).map(
          ({ Image, title, format }: any, idx) => (
            <div
              key={idx}
              className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative md:gap-4 xs:gap-[8px] "
            >
              {format === 'svg' ? (
                <Image className="lg:w-[213px] md:w-[155px] xs:w-[155px] h-max rounded-xl" />
              ) : (
                <img
                  src={Image}
                  className="lg:w-[213px] md:w-[155px] xs:w-[155px] h-max rounded-xl"
                />
              )}
              <p
                className="flex-grow-0 flex-shrink-0 lg:w-[213px] xs:w-[155px]
               md:text-xl xs:text-16 font-medium text-center text-[#0c062c]"
              >
                {title}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default WhoIsFor;
