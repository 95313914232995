// BusinessModels.tsx
import React, { useState, useRef } from 'react';
import { businessTypes, businessSelectData, BusinessType } from './menu';
import BusinessTypeMenuItem from './components/BusinessTypeMenuItem';
import BusinessTypeSelector from '../../components/businessTypeSelector';

const BusinessModels: React.FC = () => {
  // State to track the selected business type
  const [selectedType, setSelectedType] = useState<BusinessType>('POD');

  // Reference for the scrolling container
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Get the business select items based on the selected type
  const currentBusinessSelect = businessSelectData[selectedType];

  return (
    <div
      className="flex flex-col justify-start items-center w-full h-full relative 
        overflow-hidden bg-white"
      id="products"
    >
      {/* Header Section */}
      <div
        data-aos="fade-up"
        className="flex flex-col justify-start items-center relative gap-4"
      >
        <p
          className="flex-grow-0 flex-shrink-0 md:w-[750px] xs:w-[80%] 
            xl:text-48 lg:text-40 md:text-32 xs:text-24  
            font-medium text-center text-[#0c062c]"
        >
          Select your e-commerce business model
        </p>
        <p
          className="flex-grow-0 flex-shrink-0 xs:text-center text-[#0c062c]/80
            xl:text-18 lg:text-16 md:text-18 xs:text-16 sm:w-[80%] xs:w-[85%]"
        >
          We offer 3 e-commerce business models — all using AI
        </p>
      </div>

      {/* Business Types Menu */}
      <BusinessTypeSelector
        businessTypes={businessTypes}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />

      {/* Business Select Items */}
      <div
        data-aos="fade-up"
        className="overflow-y-hidden md:overflow-x-scroll xs:overflow-x-auto flex justify-start items-start 
          relative gap-5 xl:mt-[48px] lg:mt-[40px] md:mt-[40px] xs:mt-[40px] 
          w-full xl:px-[140px] lg:px-[120px] md:px-[60px] xs:px-[30px]"
        ref={scrollContainerRef}
        style={{ scrollbarWidth: 'none' }}
      >
        {currentBusinessSelect.map(({ text, description, image }, idx) => (
          <div
            key={idx}
            className="flex-grow-0 flex-shrink-0 flex-col cursor-pointer
              md:w-[380px] md:h-[460px] xs:w-[300px] xs:h-[460px]
              relative xs:px-[16px] xs:py-[20px] lg:px-[20px] lg:py-[22px] 
              bg-[#eaeaee] rounded-[16px] mb-2 transition-transform transform"
          >
            <img
              src={image}
              alt={text}
              className="rounded-[13px] w-full h-56 object-cover"
            />
            <div className="flex flex-col justify-start items-start py-4 mb-2 gap-3">
              <p
                className="self-stretch flex-grow-0 flex-shrink-0 xs:text-20 font-medium text-left
                   text-[#0c062c]"
              >
                {text}
              </p>
              <p className="self-stretch flex-grow-0 flex-shrink-0 xs:text-16 text-left text-[#0c062c]/80">
                {description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessModels;
