import { priceTableList } from './menu';
import unhappyImage from '../../assets/pages/PriceTable/unhappy.png';
import happyImage from '../../assets/pages/PriceTable/happy.png';
import starsImage from '../../assets/pages/PriceTable/stars.png';
import partyImage from '../../assets/pages/PriceTable/party.png';

import ellipseLaunchiCost from '../../assets/animations/launchifyai-cost.json';
import ellipsePrice from '../../assets/animations/pricing.json';
import Lottie from 'react-lottie-player';
const PriceTable = () => {
  const handleScroll = (event: any, targetId: string) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col items-center jutify-center xl:pb-[100px] lg:pb-[40px] xl:pt-[120px] lg:pt-[80px]  bg-white">
      <p
        data-aos="fade-up"
        className="flex-grow-0 flex-shrink-0  
      xl:text-48 lg:text-40 md:text-32
      
      font-medium text-center text-[#0c062c] "
      >
        <span
          className="flex-grow-0 flex-shrink-0 
      xl:text-48 lg:text-40 md:text-32 xs:text-24 font-medium text-center text-[#0c062c]"
        >
          Discover the true costs of
        </span>
        <br />
        <span
          className="flex-grow-0 flex-shrink-0 xs:text-24
      xl:text-48 lg:text-40 md:text-32 font-medium text-center text-[#0c062c]"
        >
          online store
        </span>
      </p>
      <div
        data-aos="fade-up"
        className="flex-grow-0 flex-shrink-0
        xl:w-[1130px] xl:h-[1024px]  lg:w-[810px] lg:h-[836px] md:h-[826px] md:w-[654px]
    
        md:flex xs:hidden
      relative xl:my-[80px] lg:my-[48px] md:my-[40px]"
      >
        <div
          className="xl:w-[1130px] xl:h-[1124px]  lg:w-[810px] lg:h-[920px] md:h-[900px] md:w-[654px]
           absolute left-[-1px] top-[-1px] xl:rounded-[40px] lg:rounded-[32px] 
           xs:rounded-[24px] bg-[#eff1f4] border border-[#eaeaee]"
          style={{ boxShadow: '0px 4px 24px 0 rgba(155,170,193,0.2)' }}
        />
        <Lottie
          loop
          animationData={ellipseLaunchiCost}
          play
          className="absolute
          xl:w-[255px] xl:h-[85px]  lg:w-[179px] lg:h-[60px] md:w-[175px] md:h-[57px]
          xl:left-[814.5px] xl:top-[41.5px] lg:left-[573px] lg:top-[15px] md:left-[440px] md:top-[9px]"
        />

        <p
          className="absolute 
          xl:left-[489px] xl:top-[63px] lg:left-[325px] lg:top-[32px] md:left-[259px] md:top-[26px]
         xl:text-28 lg:text-20 md:text-18
         font-medium text-center text-[#0c062c]"
        >
          Outsourcing costs
        </p>
        <p
          className=" absolute left-10 
          xl:top-[70px] lg:top-[32px] md:top-[26px]
        xl:text-28 lg:text-20
         font-medium text-left text-[#0c062c]"
        >
          Services
        </p>
        <p
          className="absolute
           xl:left-[836px] xl:top-[60px]  lg:left-[585px] lg:top-[32px] md:left-[463px] md:top-[26px]
        xl:text-28  lg:text-20 md:text-18
        font-medium text-center text-[#0c062c]"
        >
          Launchify.ai cost
        </p>
        <div
          className="flex flex-col justify-start items-start 
        xl:w-[1129px] lg:w-[808px] md:w-[653px]
        absolute left-px xl:top-[174px] lg:top-[120px] md:top-[120px] "
        >
          {priceTableList.map((pricing, idx) => (
            <div
              className={`flex justify-start items-center 
            self-stretch flex-grow-0 flex-shrink-0 
            xl:h-[70px] lg:h-[60px] md:h-[60px]
            relative gap-2.5 xl:pl-10 lg:pl-[32px] md:pl-[24px] pr-4 py-4 ${
              idx % 2 === 0 ? 'bg-white' : 'bg-[#eff1f4]'
            }`}
              key={idx}
            >
              <p
                className="flex-grow-0 flex-shrink-0 
              xl:text-28 lg:text-24 md:text-20 
              text-left text-[#0c062c]"
              >
                {pricing.service}
              </p>
            </div>
          ))}
          <div className="flex w-full justify-center items-center">
            <div
              onClick={(e) => handleScroll(e, 'pricing')}
              className="flex justify-center items-center xl:w-[1058px] lg:w-[746px] md:w-[606px] md:h-[64px] xl:h-[86px]
               lg:h-[64px] xl:mt-[64px] lg:mt-[48px] md:mt-[40px] border-[#9b17e4] border
           relative gap-2.5 px-7 pt-[18px] pb-5 rounded-[50px] cursor-pointer bg-white hover:bg-[#F3E2FC] active:bg-[#E6C4F9]"
            >
              <p className="flex-grow-0 flex-shrink-0 xl:text-[22px] lg:text-[20px] font-medium text-center uppercase text-[#9b17e4]">
                See our plans
              </p>
            </div>
          </div>
        </div>
        <div
          className="
        absolute
        xl:w-[300px] xl:h-[850px] lg:w-[235px] lg:h-[724px] md:w-[184px] md:h-[727px]
        
         xl:left-[449px] xl:top-[133px] lg:left-[287px] lg:top-[80px] md:left-[238px] md:top-[75px]
         xl:rounded-[40px] lg:rounded-[32px] xs:rounded-[24px] bg-[#6839ee]/[0.08]"
        />
        <div
          className=" xl:w-[300px] xl:h-[850px] lg:w-[235px] lg:h-[724px] md:w-[184px] md:h-[727px]
           absolute 
           xl:left-[789px] xl:top-[133px] lg:left-[541px] lg:top-[80px] md:left-[445px] md:top-[75px]
           
           xl:rounded-[40px] lg:rounded-[32px] xs:rounded-[24px]"
          style={{
            background:
              'linear-gradient(to bottom, #714bdb 0%, #9530ff 46.57%, #714bdb 100%)',
          }}
        />
        <p
          className="absolute 
        xl:left-[482px] xl:top-[892px] lg:left-[300px] lg:top-[725px] md:left-[240px] md:top-[725px] 
        xl:text-[32px] lg:text-[28px] md:text-[24px]
        
        text-left text-[#f82f0c]"
        >
          $9,950 minimum
        </p>

        <div className="w-[300px] h-[70px] absolute left-[789px] top-[873px] bg-white/[0.08]" />
        <Lottie
          loop
          animationData={ellipsePrice}
          play
          className="absolute
          xl:left-[824px] xl:top-[865px] lg:left-[543px] lg:top-[700px] md:left-[455px] md:top-[720px]
          lg:w-[248px] lg:h-[95px] md:w-[180px] md:h-[74px]"
        />

        <div
          className="absolute flex flex-col
    xl:left-[900px] xl:top-[855px] lg:left-[625px] lg:top-[700px] md:left-[500px] md:top-[720px]
    xl:text-[48px] lg:text-[40px] md:text-[32px]
    font-medium text-left text-[#c8f754] items-center"
        >
          <p
            className="
      xl:text-[48px] lg:text-[40px] md:text-[32px]
      font-medium text-[#c8f754]"
          >
            $97
          </p>

          <p className="md:block xs:hidden w-full text-lg font-medium text-center text-[#c8f754] -mt-2">
            per month
          </p>
        </div>

        <div
          className="flex flex-col justify-between items-center absolute
        xl:h-[669px]  xl:left-[535px] xl:top-[190px] lg:h-[568px]  lg:left-[347px] lg:top-[126px]
        md:h-[572px]  md:left-[279px] md:top-[129px]
        "
        >
          {priceTableList
            .filter((pricing) => pricing?.price)
            .map((pricing, idx) => (
              <div
                className={`flex-grow-0 flex-shrink-0 
              xl:text-28 
              font-medium text-left text-[#0c062c]`}
                key={idx}
              >
                {pricing.price && (
                  <p
                    className="flex-grow-0 flex-shrink-0 
              xl:text-28 lg:text-24 md:text-20
              text-left text-[#0c062c]"
                  >
                    {pricing.price}
                  </p>
                )}
              </div>
            ))}
        </div>

        <p
          className="absolute
         xl:left-[865px] xl:top-[523px]  lg:left-[594px] lg:top-[440px]  md:left-[483px] md:top-[411px]  
         xl:text-28 lg:text-24 md:text-18 font-medium text-center text-white"
        >
          <span className="xl:text-28 font-medium text-center text-white">
            Included in
          </span>
          <br />
          <span className="xl:text-28 font-medium text-center text-white">
            subscription
          </span>
        </p>
      </div>
      {/* mobile */}
      <div
        data-aos="fade-up"
        className="flex w-full flex-row justify-center items-center mt-[40px] md:hidden"
      >
        <div className="self-stretch flex-grow-0 justify-center  flex-shrink-0 h-[803px] relative w-[360px]">
          <div
            className="sm:w-[358px] xs:w-[328px] h-[806px] absolute sm:left-[-1px] xs:left-[15px] top-[-1px] rounded-3xl bg-[#eff1f4] border border-[#eaeaee]"
            style={{ boxShadow: '0px 4px 24px 0 rgba(155,170,193,0.2)' }}
          />
          <p className="absolute left-[207px] top-6 text-base text-center text-[#0c062c]/80">
            Outsourcing costs
          </p>
          <p className="w-[178px] absolute sm:left-4 xs:left-6 top-6 text-base text-left text-[#0c062c]/80">
            Services
          </p>
          <div className="flex flex-col justify-start items-start sm:w-[357px] xs:w-[328px] absolute sm:left-0 xs:left-[15px] top-[75px] overflow-hidden">
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Logo Design
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 px-4 py-2.5 bg-[#eff1f4]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Website Design
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Product Photography
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 px-4 py-2.5 bg-[#eff1f4]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Social Media Design
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Copywriting
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 px-4 py-2.5 bg-[#eff1f4]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Email Marketing
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Social Media Marketing{' '}
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 px-4 py-2.5 bg-[#eff1f4]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Warehouse Hosting
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Stock Expenses
              </p>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 h-[60px] relative gap-2.5 px-4 py-2.5 bg-[#eff1f4]">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c]">
                Shipping Management
              </p>
            </div>

            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0  h-[100px] relative gap-2.5 p-4 bg-white">
              <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#0c062c] ">
                Total Cost
              </p>
            </div>
          </div>
          <div className="sm:w-36 xs:w-[117px] h-[732px] absolute sm:left-[197px] xs:left-[210px] top-14 rounded-3xl bg-[#6839ee]/[0.08]" />
          <img
            src={unhappyImage}
            className="w-8 h-8 absolute left-[254.1px] top-[669.1px] object-cover"
          />
          <p className="w-[141px] absolute left-[201px] top-[705px] font-medium text-center text-[#020315]">
            <span className="w-[141px] text-[28px] font-medium text-center text-[#f82f0c]">
              $9,950{' '}
            </span>
            <span className="w-[141px] text-xl font-medium text-center text-[#f82f0c]">
              minimum
            </span>
          </p>
          <p className="absolute left-[837px] top-[905px] text-lg font-medium text-left text-white">
            per month
          </p>
          <div className="flex flex-col justify-between items-center h-[572px] absolute left-[222px] top-[88px]">
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $150 +
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $1500 +
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $500+
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $400+
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $500+
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $500+
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $300+
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $150 + pm
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $5,000 ++
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#020315]">
              $950 +
            </p>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 
       relative gap-4 xl:mt-[80px] lg:mt-[86px] md:mt-[64px] xs:mt-[16px]"
      >
        <p
          className="self-stretch flex-grow-0 flex-shrink-0 md:mt-0 xs:mt-[30px] 
         xl:text-[20px] lg:text-[20px] xs:text-[18px] md:w-auto 
         text-center text-[#0c062c]/80"
        >
          <span className="self-stretch flex-grow-0 flex-shrink-0  text-xl text-center text-[#0c062c]/80">
            It will cost you THOUSANDS of dollars before you can{' '}
          </span>
          <br />
          <span
            className="self-stretch flex-grow-0 flex-shrink-0 
          text-xl 
          text-center text-[#0c062c]/80"
          >
            even start making sales!
          </span>
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="md:hidden xl:mt-[80px] lg:mt-[48px] md:mt-[40px] xs:mt-[80px]"
      >
        <p className="sm:w-[335px] xs:w-[328px] text-center text-[#0c062c]/80">
          <span className="sm:w-[335px] xs:w-[328px] text-xl font-medium text-center text-[#0c062c]/80">
            Launchify
          </span>
          <span className="sm:w-[335px] xs:w-[328px] text-lg text-center text-[#0c062c]/80">
            {' '}
            makes affordable for everyone
          </span>
        </p>
        <div className="sm:w-[335px] xs:w-[328px] h-60 relative mt-[40px]">
          <div
            className="sm:w-[367px] xs:w-[355px] sm:left-[-16px]
           sm:h-[336px] xs:top-[-13.5px] xs:left-[-13.5px] xs:h-[336px]  sm:top-[-16px] rounded-[40px] absolute bg-[#EFF1F4] ounded-3xl"
          />
          <div
            className="sm:w-[335px] xs:w-[328px] h-60 absolute left-[-1px] top-[-1px] rounded-3xl"
            style={{
              background:
                'linear-gradient(to bottom, #714bdb 0%, #9530ff 46.57%, #714bdb 100%)',
            }}
          />
          <p className=" absolute top-[164px] w-full text-lg text-center text-white px-8">
            Everything is included in the subscription
          </p>
          <button
            onClick={(e) => handleScroll(e, 'pricing')}
            className="flex justify-center items-center self-stretch flex-grow-0 border border-[#9b17e4]
          z-20
          top-[250px] sm:w-[335px] xs:w-[328px]
          flex-shrink-0 h-14 absolute gap-2.5 px-7 pt-[18px] pb-5 rounded-[50px] cursor-pointer bg-white hover:bg-[#F3E2FC] active:bg-[#E6C4F9]"
          >
            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-center uppercase text-[#9b17e4]">
              See our plans
            </p>
          </button>
          <div className="flex justify-center items-end absolute top-3.5 gap-2 w-full text-center">
            <p className="flex-grow-0 flex-shrink-0 text-[28px] font-medium text-center text-white">
              Launchify.ai{' '}
            </p>
            <div className="flex justify-start items-end flex-grow-0 flex-shrink-0 relative gap-2.5 pb-1">
              <p className="flex-grow-0 flex-shrink-0 text-xl text-center text-white">
                cost
              </p>
            </div>
          </div>
          <div className="w-[358px] h-[72px] absolute left-[-1px] top-[67px] bg-white/[0.08]" />
          {/* <svg
            width={130}
            height={89}
            viewBox="0 0 130 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="absolute left-[110px] top-[60px]"
          >
            <path
              d="M122.535 49.484C140 -5.50008 0.999593 -21 1 42.5148C1.00041 106.03 125 98 129 45.5"
              stroke="#49BF26"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg> */}
          <Lottie
            loop
            animationData={ellipsePrice}
            play
            className="absolute left-[75px] top-[60px] w-[200px]"
          />

          <img
            src={partyImage}
            className="w-10 h-10 absolute left-[245px] top-[57.79px] object-cover "
          />
          <img
            src={happyImage}
            className="w-10 h-10 absolute left-[80px] top-[110px] object-cover"
          />
          <img
            src={starsImage}
            className="w-7 h-7 absolute left-[50px] top-[67px] object-cover"
          />
          <p className="absolute left-[132px] top-[70px] text-base font-medium text-left text-[#c8f754]">
            $
          </p>
          <p className="absolute left-[145px] top-[60px] text-[48px] font-medium text-left text-[#c8f754]">
            97
          </p>
          <p className="absolute left-[130px] top-[113px] text-lg font-medium text-left text-[#c8f754]">
            per month
          </p>
        </div>
      </div>
    </div>
  );
};
export default PriceTable;
