import { ReactComponent as LogoAsset } from '../../../assets/main/logo.svg';
import { ReactComponent as LogoTextAsset } from '../../../assets/main/logoText.svg';

const LogoCard = () => {
  return (
    <div id="logo-card" className="flex justify-center items-start ">
      <div
        className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0
         xl:h-[50px] lg:h-[45px] md:h-[40px] xs:h-[40px] px-4 py-1 xl:rounded-[40px] lg:rounded-[36px] md:rounded-[32px] md:bg-white xs:bg-transparent
         md:shadow-md"
      >
        <div
          className="flex md:justify-center items-center 
         flex-grow-0 flex-shrink-0 relative xs:gap-0 md:gap-[9px]"
        >
          <LogoAsset className="xl:h-[26px] lg:h-[22px] md:h-[22px] xs:h-[20px]" />
          <LogoTextAsset className="xl:h-[16px] lg:h-[15px] md:h-[15px] xs:h-[14px] md:w-auto xs:w-[120px]" />
        </div>
      </div>
    </div>
  );
};
export default LogoCard;
