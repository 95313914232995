import { colors } from '../../styles/colors';
import './index.css';
const Navbar = () => {
  const handleScroll = (event: any, targetId: string) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="flex justify-start items-center relative font-outfit xl:text-20 lg:text-18 md:text-18 md:flex xs:hidden
      gap-8 xl:pl-12 md:pl-8 pr-2 py-2 rounded-[36px] bg-white font-[400] xl:h-[72px] lg:h-[64px] md:h-[64px]"
      style={{
        boxShadow:
          '0px -16px 40px 0 rgba(34,32,80,0.08), 0px 20px 20px 0 rgba(0,0,0,0.06)',
      }}
    >
      <p
        onClick={(e) => handleScroll(e, 'solutions')}
        className={`flex-grow-0 flex-shrink-0 text-center text-[#0c062c] 
        cursor-pointer  hover:text-[#A72DEA] `}
      >
        Solutions
      </p>
      <p
        onClick={(e) => handleScroll(e, 'process-title')}
        className="flex-grow-0 flex-shrink-0  text-center text-[#0c062c] cursor-pointer  hover:text-[#A72DEA] "
      >
        Process
      </p>
      <p
        onClick={(e) => handleScroll(e, 'products')}
        className="flex-grow-0 flex-shrink-0 text-center text-[#0c062c] cursor-pointer  hover:text-[#A72DEA] "
      >
        Integration
      </p>
      <p
        onClick={(e) => handleScroll(e, 'pricing')}
        className="flex-grow-0 flex-shrink-0  text-center text-[#0c062c] cursor-pointer  hover:text-[#A72DEA] "
      >
        Pricing
      </p>
      <div
        className="flex justify-center items-center flex-grow-0 flex-shrink-0
         xl:h-14 lg:h-12 md:h-12 relative gap-2.5 px-7 rounded-[40px] free-trial-btn"
        onClick={(e) => handleScroll(e, 'pricing')}
      >
        <p
          className="flex-grow-0 flex-shrink-0 font-medium cursor-pointer
        text-center text-white "
        >
          Start Free Trial
        </p>
      </div>
    </div>
  );
};
export default Navbar;
