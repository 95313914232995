import { ReactComponent as InstagramAsset } from '../../assets/socials/instagram.svg';
import { ReactComponent as FacebookAsset } from '../../assets/socials/facebook.svg';
import { ReactComponent as LinkeInAsset } from '../../assets/socials/linkeIn.svg';

export const socials = [
  {
    title: 'Instagram',
    Icon: InstagramAsset,
    href: '',
  },

  {
    title: 'LinkeIn',
    Icon: LinkeInAsset,
    href: '',
  },
  {
    title: 'Facebook',
    Icon: FacebookAsset,
    href: '',
  },
];
