import { ReactComponent as LampAsset } from '../../assets/hashtags/lamp.svg';
import { ReactComponent as PeopleAsset } from '../../assets/hashtags/people.svg';
import { ReactComponent as InventoryAsset } from '../../assets/hashtags/inventory.svg';
import { ReactComponent as FulfillmentAsset } from '../../assets/hashtags/fulfillment.svg';
import { ReactComponent as ShippingAsset } from '../../assets/hashtags/shipping.svg';
import { ReactComponent as OkayIconAsset } from '../../assets/icons/okay.svg';

import { ReactComponent as TikTokAsset } from '../../assets/logotypes/tiktok.svg';

import { ReactComponent as ShopifyAsset } from '../../assets/logotypes/shopify.svg';
import { ReactComponent as MetaAsset } from '../../assets/logotypes/meta.svg';
import { ReactComponent as InstagramAsset } from '../../assets/logotypes/instagram.svg';
import { ReactComponent as EtsyAsset } from '../../assets/logotypes/etsy.svg';
import { ReactComponent as AmazonAsset } from '../../assets/logotypes/amazon.svg';

import { ReactComponent as ClockAsset } from '../../assets/icons/explanations/clock.svg';
import { ReactComponent as AiAsset } from '../../assets/icons/explanations/ai.svg';
import { ReactComponent as HouseAsset } from '../../assets/icons/explanations/house.svg';

import { ReactComponent as SignUpAsset } from '../../assets/icons/progress/sign-up.svg';
import { ReactComponent as BusinessAsset } from '../../assets/icons/progress/business.svg';
import { ReactComponent as ItemsAsset } from '../../assets/icons/progress/items.svg';
import { ReactComponent as SelleingAsset } from '../../assets/icons/progress/selling.svg';
import { colors } from '../../styles/colors';
export const hashtagsRowOne = [
  {
    Icon: LampAsset,
    text: 'tech skills',
    color: colors.main.secondary.alpha[16],
  },
  {
    Icon: PeopleAsset,
    text: 'hiring staff',
    color: colors.main.textIcon['light-60'],
  },
];
export const hashtagsRowTwo = [
  {
    Icon: InventoryAsset,
    text: 'buying inventory',
    color: colors.main.tertiary[400],
  },
  {
    Icon: FulfillmentAsset,
    text: 'fulfillment',
    color: '#E8E8E8',
  },
  {
    Icon: ShippingAsset,
    text: 'shipping',
    color: colors.main.primary.alpha[16],
  },
];

export const businessTypes = [
  {
    Icon: OkayIconAsset,
    text: 'Print on Demand',
    color: '#C78DA3',
  },
  {
    Icon: OkayIconAsset,
    text: 'Dropshipping',
    color: '#C78DA3',
  },
  {
    Icon: OkayIconAsset,
    text: 'Branded Business',
    color: '#C78DA3',
  },
];

export const logotypes = [
  {
    Icon: TikTokAsset,
  },
  {
    Icon: ShopifyAsset,
  },
  {
    Icon: MetaAsset,
  },
  {
    Icon: InstagramAsset,
  },
  {
    Icon: EtsyAsset,
  },
  {
    Icon: AmazonAsset,
  },
];

export const explanationCards = [
  {
    Icon: ClockAsset,
    text: 'Rapid Setup - 10 mins',
    description:
      'Launch your e-commerce swiftly. Our intuitive interface ensures a hassle-free setup, empowering you to focus on selling within minutes.',
  },
  {
    Icon: AiAsset,
    text: 'AI-Driven. No developers, designers or copywriters required',
    description:
      'Sell profit driven items effortlessly with our AI engine, putting you 95% ahead of other ecom sellers.',
  },
  {
    Icon: HouseAsset,
    text: 'Worried about inventory, shipping and fulfilment? We got you covered',
    description:
      'Pick from over 900 products, use our AI to design them, sell them and everything is fulfilled and shipped automatically worldwide. ',
  },
];

export const progressSteps = [
  {
    Icon: SignUpAsset,
    text: 'Sign-up',
    description: 'Sign-up to Launchify',
    subDescription: '1 min',
  },
  {
    Icon: BusinessAsset,
    text: 'Select a business',
    description:
      'Create a brand with your logo and colours. Select a template for you online store',
    subDescription: '5 min',
  },
  {
    Icon: ItemsAsset,
    text: 'Select items',
    description:
      'Pick over 900 products and sell with AI-generated product content and designs',
    subDescription: '3 min',
  },
  {
    Icon: SelleingAsset,
    text: 'Start selling',
    description:
      'Publish AI created designs to Shopify, Amazon & Esty in seconds and watch the sales come in',
    subDescription: '1 min',
  },
];
