import { socials } from './menu';

const Footer = () => {
  return (
    <div
      className="flex md:py-[80px] xs:py-[38px]  w-full justify-center items-center relative
     overflow-hidden bg-[#0c062c]"
    >
      <div
        className="z-10 md:w-[80%] xs:w-[90%] md:h-[361px] xs:h-auto lg:pl-[80px] 
        xs:pl-[32px] md:pl-[40px] rounded-[40px] flex flex-col 
         justify-center items-center"
        style={{
          background:
            'linear-gradient(89.56deg, #5f33cd 0.24%, #9430d4 134.61%)',
        }}
      >
        <div
          data-aos="fade-up"
          className="flex md:flex-row xs:flex-col justify-start w-full items-start 
        lg:gap-[120px] md:gap-[80px] xs:gap-[32px] md:h-auto xs:h-full md:pt-0 xs:pt-[32px]"
        >
          <div
            className="flex flex-col justify-start items-start 
          flex-grow-0 flex-shrink-0 relative md:gap-8 xs:gap-4"
          >
            <p
              className="flex-grow-0 flex-shrink-0 xl:text-[28px] 
            lg:text-[24px] xs:text-[20px] font-medium text-left text-white "
            >
              Support
            </p>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative md:gap-4 xs:gap-2">
              <p className="flex-grow-0 flex-shrink-0 lg:text-[16px]  xs:text-[14px]  text-center text-white/80 hover:text-[#E2A4F8] cursor-pointer ">
                Frequently Asked Questions
              </p>
              <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative">
                <p className="flex-grow-0 flex-shrink-0 lg:text-[16px]  xs:text-[14px] border-b border-dotted  text-center text-white/80 hover:text-[#E2A4F8] cursor-pointer ">
                  Contact Customer Success
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative md:gap-8 xs:gap-4">
            <p className="flex-grow-0 flex-shrink-0 xl:text-[28px] lg:text-[24px] xs:text-[20px] font-medium text-left text-white">
              Trust &amp; Legal
            </p>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative md:gap-4 xs:gap-2">
              <p className="flex-grow-0 flex-shrink-0 lg:text-[16px]  xs:text-[14px]  text-center text-white/80 hover:text-[#E2A4F8] cursor-pointer">
                Terms &amp; Conditions
              </p>
              <p className="flex-grow-0 flex-shrink-0 lg:text-[16px]  xs:text-[14px]  text-center text-white/80 hover:text-[#E2A4F8] cursor-pointer">
                Privacy Policy
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative md:gap-8 xs:gap-4">
            <p className="flex-grow-0 flex-shrink-0 xl:text-[28px] lg:text-[24px] xs:text-[20px] font-medium text-left text-white">
              Social
            </p>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 md:gap-4 xs:gap-2">
              {socials.map(({ title, href, Icon }) => {
                return (
                  <div className="group">
                    <a
                      className="flex text-white/80 hover:text-[#E2A4F8] justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1"
                      href={href}
                    >
                      <Icon className="flex-grow-0 flex-shrink-0 w-4 h-4 relative group-hover:fill-[#E2A4F8]" />

                      <p
                        className="flex-grow-0 flex-shrink-0 lg:text-[16px] xs:text-[14px] 
    text-center cursor-pointer"
                      >
                        {title}
                      </p>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex md:justify-start xs:justify-center  items-center w-full gap-2 md:pt-16 xs:pt-12 
        md:pr-0 xs:pr-[32px]"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 md:pb-0 xs:pb-[16px]">
            <p className="flex-grow-0 flex-shrink-0 text-[32px] top-0  text-left text-white/80 ">
              ®
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white/80  mb-1.5 ">
              <span className="flex-grow-0 flex-shrink-0 text-sm text-left uppercase text-white/80  ">
                2024 L
              </span>
              <span className="flex-grow-0 flex-shrink-0 text-sm text-left text-white/80  ">
                aunchify. All rights reserved.
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Left Bottom */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring absolute w-[950px] h-[950px]  left-[-20%] top-0 "
      ></div>
    </div>
  );
};

export default Footer;
