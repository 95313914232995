import image0 from '../../assets/pages/OnePlace/images/0_2x.png';
import image1 from '../../assets/pages/OnePlace/images/1_2x.jpg';
import image2 from '../../assets/pages/OnePlace/images/2_2x.jpg';
import image3 from '../../assets/pages/OnePlace/images/3_2x.jpg';
import image4 from '../../assets/pages/OnePlace/images/4_2x.jpg';
import image5 from '../../assets/pages/OnePlace/images/5_2x.jpg';
import image6 from '../../assets/pages/OnePlace/images/6_2x.jpg';
import image7 from '../../assets/pages/OnePlace/images/7_2x.gif';
import image8 from '../../assets/pages/OnePlace/images/8_2x.jpg';
import image9 from '../../assets/pages/OnePlace/images/9_2x.jpg';
import image10 from '../../assets/pages/OnePlace/images/10_2x.jpg';
import image11 from '../../assets/pages/OnePlace/images/11_2x.jpg';
import image12 from '../../assets/pages/OnePlace/images/12_2x.jpg';
import image13 from '../../assets/pages/OnePlace/images/13_2x.jpg';
import image14 from '../../assets/pages/OnePlace/images/14_2x.jpg';
import image15 from '../../assets/pages/OnePlace/images/15_2x.jpg';
import image16 from '../../assets/pages/OnePlace/images/16_2x.jpg';
import image17 from '../../assets/pages/OnePlace/images/17_2x.jpg';

export const images = [
  [
    {
      image: image0,
    },
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
    {
      image: image4,
    },
    {
      image: image5,
    },
  ],
  [
    {
      image: image6,
    },
    {
      image: image9,
    },
    {
      image: image8,
    },
    {
      image: image7,
    },
    {
      image: image10,
    },
    {
      image: image11,
    },
  ],
  [
    {
      image: image12,
    },
    {
      image: image13,
    },
    {
      image: image14,
    },
    {
      image: image15,
    },
    {
      image: image16,
    },
    {
      image: image17,
    },
  ],
];
