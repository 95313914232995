import logoMobile from '../../assets/main/logo-mobile.gif';
import logoMobileWhite from '../../assets/main/logo-desktop-white.gif';
import { ReactComponent as LogoAsset } from '../../assets/main/logo.svg';
import { ReactComponent as LogoTextAsset } from '../../assets/main/logoText.svg';
import { images } from './menu';
const OnePlace = () => {
  return (
    <div
      id="solutions"
      className="flex bg-[#020315] xl:py-[120px] lg:py-[96px] md:py-[80px] xs:py-[80px] flex-col justify-start items-center
       w-full h-full relative overflow-hidden"
    >
      <div
        data-aos="fade-up"
        className="flex flex-col justify-start items-center relative gap-4 xl:mb-[80px] lg:mb-[64px] xs:mb-[80px] z-20"
      >
        <p
          className="flex-grow-0 flex-shrink-0 
        xl:text-5xl lg:text-[40px] md:text-[32px] xs:text-24
        font-medium text-center text-white"
        >
          Everything in one place
        </p>
        <p
          className="flex-grow-0 flex-shrink-0 xl:text-lg md:text-[16px] xs:text-16 md:w-auto xs:w-[70%]
          md:text-left xs:text-center text-white/80"
        >
          Launchify has everything you need in one place to get started selling
          products online…
        </p>
      </div>
      <div
        data-aos="fade-up"
        className=" z-20 lg:w-[871px] lg:h-[584px] md:w-[688px] md:h-[584px] relative md:block xs:hidden "
      >
        <div className="lg:w-[162px] lg:h-[162px] md:w-[129.6px] md:h-[129.6px]  absolute lg:left-[345px] md:left-[280px] top-[230px]">
          <div
            className="lg:w-[162px] lg:h-[162px] md:w-[129.6px] md:h-[129.6px] 
            absolute left-[-1px] top-[-1px] rounded-3xl bg-[#6839ee]/[0.24]"
          />
          <img
            className="lg:w-[140px] lg:h-[140px] md:w-28 md:h-28 absolute 
          
          md:left-2 md:top-2.5
          lg:left-2.5 lg:top-2.5 lg:rounded-[20px] md:rounded-[15px] bg-[#eff1f4]"
            src={logoMobileWhite}
          />
        </div>
        {/* Left Corner */}
        <div className="flex flex-col justify-start items-start absolute left-[-1px] lg:top-[131px] md:top-[135px] lg:gap-5 md:gap-4">
          <div
            className="flex justify-center items-center self-stretch 
          flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]"
          >
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI Logotype Maker
            </p>
          </div>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 lg:w-[209px] md:w-[190px] lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI Image Generation
            </p>
          </div>
          <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI POD Designs
            </p>
          </div>
          <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI Copywriting
            </p>
          </div>
          <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI Design Editor
            </p>
          </div>
        </div>
        {/* Right Corner */}
        <div className="flex flex-col justify-start items-start absolute   lg:left-[643px] md:right-0  lg:top-[131px] md:top-[135px] lg:gap-5 md:gap-4">
          <div
            className="flex justify-center items-center self-stretch 
          flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]"
          >
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              AI Store Builder
            </p>
          </div>
          <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-center text-white">
              Content Publishing
            </p>
          </div>
          <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white">
              Multi-platform Publishing
            </p>
          </div>
          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-[18px]">
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-center text-white">
                Social Media Publishing
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-center text-white">
                Multi Product Publishing
              </p>
            </div>
          </div>
        </div>
        {/* Bottom Corner */}
        <div className="flex justify-center items-center w-full absolute   lg:top-[528px] md:top-[497px] lg:gap-5 md:gap-4 bottom-[-1px]">
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#9b17e4]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white/80">
              Automatic Shipping
            </p>
          </div>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#9b17e4]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-left text-white/80">
              Automatic Fulfilment
            </p>
          </div>
        </div>
        {/* Top Corner */}
        <div className="flex justify-center items-center w-full absolute  lg:top-[37px] md:top-[44px] lg:gap-5 md:gap-4">
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#9b17e4]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-center text-white/80">
              Facebook Ads Training
            </p>
          </div>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 lg:h-14 md:h-12 relative  md:px-4 py-2.5 md:rounded-2xl xs:rounded-xl bg-[#9b17e4]">
            <p className="flex-grow-0 flex-shrink-0 lg:text-base md:text-sm text-center text-white/80">
              TikTok Ads Training
            </p>
          </div>
        </div>
        <p className="absolute left-[7px] lg:top-[93px] md:top-[100px] opacity-70 lg:text-base md:text-sm text-left uppercase text-white/80">
          Content Generation
        </p>
        <p className="absolute lg:left-[689px] md:left-[540px] w-max lg:top-[93px] md:top-[100px] opacity-70 lg:text-base md:text-sm text-left uppercase text-white/80">
          Business Setup
        </p>
        <p className="absolute w-full flex justify-center items-center  lg:top-0 md:top-[10px] opacity-70 lg:text-base md:text-sm text-left uppercase text-white/80">
          Ads Lead Training
        </p>
        <p className="absolute w-full flex justify-center items-center  lg:top-[490px] md:top-[470px] opacity-70 lg:text-base md:text-sm text-left uppercase text-white/80">
          Simple Operation
        </p>

        <div className="left-[209px] top-[94px] hidden lg:absolute lg:block">
          <svg
            width={434}
            height={434}
            viewBox="0 0 434 434"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[434px] h-[434px] relative"
            preserveAspectRatio="none"
          >
            <path
              d="M136 217C69 217 68 294 10 294H0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M5 294.5C71 293 72 217 134 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M0 288V300L9 294L0 288Z" fill="#601EEC" />
            <path
              d="M298 217C365 217 366 294 424 294H434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M429 294.5C363 293 362 217 300 217"
              stroke="#6927DA"
              strokeDasharray="4 4"
            />
            <path d="M434 288V300L425 294L434 288Z" fill="#601EEC" />
            <path
              d="M136 217C69 217 68 140 10 140H0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M5 139.5C71 141 72 217 134 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M0 146V134L9 140L0 146Z" fill="#601EEC" />
            <path
              d="M298 217C365 217 366 140 424 140H434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M429 139.5C363 141 362 217 300 217"
              stroke="#6927DA"
              strokeDasharray="4 4"
            />
            <path d="M434 146V134L425 140L434 146Z" fill="#601EEC" />
            <path
              d="M136 216C69 216 68 216 10 216H0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M5 216C60 216 72 216 134 216"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M0 222V210L9 216L0 222Z" fill="#601EEC" />
            <path
              d="M298 216C365 216 366 216 424 216H434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M429 216C374 216 362 216 300 216"
              stroke="#6927DA"
              strokeDasharray="4 4"
            />
            <path d="M434 222V210L425 216L434 222Z" fill="#601EEC" />
            <path
              d="M136 217C48 217 96.1169 73.4654 10 65H0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M10 65C96.1169 73.4654 48.5 217 134 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M127 223V211L136 217L127 223Z" fill="#601EEC" />
            <path d="M0 71V59L9 65L0 71Z" fill="#601EEC" />
            <path
              d="M298 217C386 217 337.883 73.4654 424 65H434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M424 65C337.883 73.4654 385.5 217 300 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M307 223V211L298 217L307 223Z" fill="#601EEC" />
            <path d="M434 71V59L425 65L434 71Z" fill="#601EEC" />
            <path
              d="M136 217C48 217 96.1169 360.535 10 369H0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M10 369C96.1169 360.535 48.5 217 134 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M127 211V223L136 217L127 211Z" fill="#601EEC" />
            <path d="M0 363V375L9 369L0 363Z" fill="#601EEC" />
            <path
              d="M223 298C223 386 320.264 337.883 326 424L326 434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M326 424C320.264 337.883 223 385.5 223 300"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M217 307L229 307L223 298L217 307Z" fill="#601EEC" />
            <path d="M320 434L332 434L326 425L320 434Z" fill="#601EEC" />
            <path
              d="M223 136C223 48 320.264 96.1169 326 10L326 0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M326 10C320.264 96.1169 223 48.5 223 134"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M217 127L229 127L223 136L217 127Z" fill="#601EEC" />
            <path
              d="M320 -5.24537e-07L332 0L326 9L320 -5.24537e-07Z"
              fill="#601EEC"
            />
            <path
              d="M223 298C223 386 125.736 337.883 120 424L120 434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M120 424C125.736 337.883 223 385.5 223 300"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M229 307L217 307L223 298L229 307Z" fill="#601EEC" />
            <path d="M126 434L114 434L120 425L126 434Z" fill="#601EEC" />
            <path
              d="M223 136C223 48 125.736 96.1169 120 10L120 0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M120 10C125.736 96.1169 223 48.5 223 134"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M229 127L217 127L223 136L229 127Z" fill="#601EEC" />
            <path
              d="M126 -5.24537e-07L114 0L120 9L126 -5.24537e-07Z"
              fill="#601EEC"
            />
            <path
              d="M298 217C386 217 337.883 360.535 424 369H434"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M424 369C337.883 360.535 385.5 217 300 217"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M307 211V223L298 217L307 211Z" fill="#601EEC" />
            <path d="M434 363V375L425 369L434 363Z" fill="#601EEC" />
          </svg>
          ;
        </div>
        <div className="left-[190px] top-[94px] lg:hidden absolute">
          <svg
            width={309}
            height={403}
            viewBox="0 0 309 403"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[309px] h-[403px] relative"
            preserveAspectRatio="none"
          >
            <path
              d="M90 197C46.1544 197 45.5 262 7.54412 262H1"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M4 262C47.4884 260.742 48.1473 197 89 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M220 197C263.846 197 264.5 262 302.456 262H309"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M306 262C262.512 260.742 261.853 197 221 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M220 197C263.846 197 264.5 132 302.456 132H309"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M306 132C262.512 133.258 261.853 197 221 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M220 197C263.846 197 264.5 197 302.456 197H309"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M306 197C269.76 197 261.853 197 221 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M220 197C277.588 197 246.1 76.1288 302.456 69H309"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M302 69C245.746 76.1288 276.851 197 221 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M220 197C277.588 197 246.1 317.871 302.456 325H309"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M302 325C245.746 317.871 276.851 197 221 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M90 197C46.1544 197 45.5 132 7.54412 132H1"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M4 132C47.4884 133.258 48.1473 197 89 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M90 197C46.1544 197 45.5 197 7.54412 197H1"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M4 197C40.2403 197 48.1473 197 89 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path
              d="M90 197C32.4118 197 63.9001 76.1288 7.54412 69H1"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M8 69C64.2538 76.1288 33.1492 197 89 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M0 256V268L9 262L0 256Z" fill="#601EEC" />
            <path d="M0 203V191L9 197L0 203Z" fill="#601EEC" />
            <path d="M0 126V138L9 132L0 126Z" fill="#601EEC" />
            <path d="M0 75V63L9 69L0 75Z" fill="#601EEC" />
            <path d="M309 203V191L302 197L309 203Z" fill="#601EEC" />
            <path d="M309 75V63L300 69L309 75Z" fill="#601EEC" />
            <path d="M309 137V125L300 131L309 137Z" fill="#601EEC" />
            <path
              d="M90 197C32.4118 197 63.9001 317.871 7.54412 325H1"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M8 325C64.2538 317.871 33.1492 197 89 197"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M82 203V191L91 197L82 203Z" fill="#601EEC" />
            <path d="M0 331V319L8 325L0 331Z" fill="#601EEC" />
            <path d="M309 331V319L301 325L309 331Z" fill="#601EEC" />
            <path
              d="M157 267C157 355 254.264 306.883 260 393L260 403"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M260 393C254.264 306.883 157 354.5 157 269"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M151 276L163 276L157 267L151 276Z" fill="#601EEC" />
            <path d="M254 403L266 403L260 394L254 403Z" fill="#601EEC" />
            <path
              d="M156 136C156 48 253.264 96.1169 259 10L259 0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M259 10C253.264 96.1169 156 48.5 156 134"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M150 127L162 127L156 136L150 127Z" fill="#601EEC" />
            <path
              d="M253 -5.24537e-07L265 0L259 9L253 -5.24537e-07Z"
              fill="#601EEC"
            />
            <path
              d="M157 267C157 355 59.7364 306.883 54 393L54 403"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M54 393C59.7364 306.883 157 354.5 157 269"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M163 276L151 276L157 267L163 276Z" fill="#601EEC" />
            <path d="M60 403L48 403L54 394L60 403Z" fill="#601EEC" />
            <path
              d="M156 136C156 48 58.7364 96.1169 53 10L53 0"
              stroke="#6839EE"
              strokeOpacity="0.08"
              strokeWidth={12}
            />
            <path
              d="M53 10C58.7364 96.1169 156 48.5 156 134"
              stroke="#601EEC"
              strokeDasharray="4 4"
            />
            <path d="M162 127L150 127L156 136L162 127Z" fill="#601EEC" />
            <path
              d="M59 -5.24537e-07L47 0L53 9L59 -5.24537e-07Z"
              fill="#601EEC"
            />
            <path d="M309 268V256L300 262L309 268Z" fill="#601EEC" />
            <path d="M220 203V191L211 197L220 203Z" fill="#601EEC" />
          </svg>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex z-20 flex-col justify-start items-center relative  md:hidden"
      >
        <img
          className="rounded-[15px]"
          width={130}
          height={130}
          src={logoMobile}
        ></img>
        <svg
          width={322}
          height={82}
          viewBox="0 0 322 82"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex-grow-0 flex-shrink-0 w-[322px] h-[81px] relative"
          preserveAspectRatio="none"
        >
          <path
            d="M161 1C161 60.6112 258 21 258 82"
            stroke="#6839EE"
            strokeOpacity="0.08"
            strokeWidth={12}
          />
          <path
            d="M258 81.5C259 25 160 59 161 2"
            stroke="#601EEC"
            strokeDasharray="4 4"
          />
          <path
            d="M64.0078 82C64.0078 22.3888 161.008 62 161.008 1"
            stroke="#6839EE"
            strokeOpacity="0.08"
            strokeWidth={12}
          />
          <path
            d="M161.008 1.5C162.008 58 63.0078 24 64.0081 81"
            stroke="#601EEC"
            strokeDasharray="4 4"
          />
        </svg>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 sm:gap-2.5 xs:gap-2">
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] gap-3">
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white">
                AI Logotype Maker
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white">
                AI Image Generation
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white">
                AI POD Designs
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white">
                AI Copywriting
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white">
                AI Design Editor
              </p>
            </div>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-10 relative px-4 py-2.5 rounded-xl bg-[#9b17e4]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white/80">
                  Facebook Ads Training
                </p>
              </div>
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] h-10 relative px-4 py-2.5 rounded-xl bg-[#9b17e4]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white/80">
                  TikTok Ads Training
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 gap-3 ">
            <div
              className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 
            h-[38px] relative px-3 py-2.5 rounded-xl bg-[#601eec]"
            >
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                AI Store Builder
              </p>
            </div>
            <div
              className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] 
            relative px-3 py-2.5 rounded-xl bg-[#601eec]"
            >
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                Content Publishing
              </p>
            </div>
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                Multi-platform Publishing
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2.5 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                Social Media Publishing
              </p>
            </div>
            <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-[38px] relative px-3 py-2.5 rounded-xl bg-[#601eec]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                Multi Product Publishing
              </p>
            </div>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] h-10 relative px-4 py-2.5 rounded-xl bg-[#9b17e4]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white/80">
                  Automatic Shipping
                </p>
              </div>
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 sm:w-[174px] xs:w-[166px] h-10 relative px-4 py-2.5 rounded-xl bg-[#9b17e4]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-white/80">
                  Automatic Fulfilment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        className="flex z-20 xl:mt-[186px] lg:mt-[160px] md:mt-[190px]  xs:mt-32 flex-col justify-start items-start gap-4 "
      >
        {/* pl-8 */}
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-4">
          {images[0].map(({ image }, idx) => (
            <div
              key={idx}
              className="flex-grow-0 flex-shrink-0 xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px] xs:w-[150px] xs:h-[110px]  relative overflow-hidden md:rounded-2xl xs:rounded-xl"
            >
              <img
                src={image}
                className="xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px]  object-cover"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-start pl-8 items-start flex-grow-0 flex-shrink-0 relative gap-4">
          {images[1].map(({ image }, idx) => (
            <div
              key={idx}
              className="flex-grow-0 flex-shrink-0 xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px] xs:w-[150px] xs:h-[110px]  relative overflow-hidden md:rounded-2xl xs:rounded-xl"
            >
              <img
                src={image}
                className="xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px]  object-cover"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-4">
          {images[2].map(({ image }, idx) => (
            <div
              key={idx}
              className="flex-grow-0 flex-shrink-0 xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px] xs:w-[150px] xs:h-[110px]  relative overflow-hidden md:rounded-2xl xs:rounded-xl"
            >
              <img
                src={image}
                className="xl:w-[270px] xl:h-[200px] lg:w-[170px] lg:h-[125px] md:w-[150px] md:h-[110px]  object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      {/* Middle Center */}
      <div
        id="bg-coloring"
        className="bg-ellipse-coloring-text absolute w-[1250px] h-[1250px] xl:bottom-[33%] lg:bottom-[20%] md:bottom-[15%] "
      ></div>
    </div>
  );
};

export default OnePlace;
