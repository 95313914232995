import { ReactComponent as ManufacturingAsset } from '../../assets/pages/stocks/steps/manufacturing.svg';
import { ReactComponent as WarehouseAsset } from '../../assets/pages/stocks/steps/warehourse.svg';
import { ReactComponent as StockAsset } from '../../assets/pages/stocks/steps/stock.svg';
import { ReactComponent as ShippingAsset } from '../../assets/pages/stocks/steps/shipping.svg';
import { ReactComponent as EmployeesAsset } from '../../assets/pages/stocks/steps/employees.svg';

export const stockCardsList = [
  {
    title: 'Manufacturing',
    description: 'Not required',
    Icon: ManufacturingAsset,
  },
  {
    title: 'Warehouse',
    description: 'Not required',
    Icon: WarehouseAsset,
  },
  {
    title: 'Stock',
    description: 'Not required',
    Icon: StockAsset,
  },
  {
    title: 'Shipping',
    description: 'Not required',
    Icon: ShippingAsset,
  },
  {
    title: 'Employees',
    description: 'Not required',
    Icon: EmployeesAsset,
  },
];
export const stockCardsListSmall = [
  {
    title: 'Manufacturing',
    description: 'Not required',
    Icon: ManufacturingAsset,
  },
  {
    title: 'Warehouse & Stock',
    description: 'Not required',
    Icon: WarehouseAsset,
  },
  {
    title: 'Shipping',
    description: 'Not required',
    Icon: ShippingAsset,
  },
  {
    title: 'Employees',
    description: `Not required`,
    Icon: EmployeesAsset,
  },
];
