export const priceTableList = [
  {
    service: 'Logo Design',
    price: '$150 +',
  },
  {
    service: 'Website Design',
    price: '$1500 +',
  },
  {
    service: 'Product Photography',
    price: '$500+',
  },
  {
    service: 'Social Media Design',
    price: '$400+',
  },
  {
    service: 'Copywriting',
    price: '$500+',
  },
  {
    service: 'Email Marketing',
    price: '$500+',
  },
  {
    service: 'Social Media Marketing ',
    price: '$300+',
  },
  {
    service: 'Warehouse Hosting',
    price: '$150 + pm',
  },
  {
    service: 'Stock Expenses',
    price: '$5,000 ++',
  },
  {
    service: 'Shipping Management',
    price: '$950 +',
  },
  {
    service: 'Total Cost',
  },
];
